
import './App.css';
import {Navbar} from "./Components/Navbar";
import {Hero} from "./Components/Hero";
import {Product} from "./Components/Product";
import {Explore} from "./Components/Explore";
import Contact from "./Components/Contact";
import {Footer} from "./Components/Footer";

function App() {
  return <>
    <Navbar></Navbar>
    <Hero></Hero>

    <Product/>
    <Contact/>
    <Footer/>
  </>;
}

export default App;
