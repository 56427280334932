
import './App.css';
import {Navbar} from "./Components/Navbar";
import {Footer} from "./Components/Footer";
import {PrivacyPolicy} from "./Components/PrivacyPolicy";

function KebijakanPrivasi() {
    return <>
        <Navbar></Navbar>
        <div className="px-12 py-8 md:px-52 md:py-36 min-h-screen">
            <h3 className="text-4xl text-center pb-20">Profil Kami</h3>
            <p className="my-5 text-justify leading-8">Kami adalah sebuah perusahaan inovatif yang berfokus pada memberikan solusi digital terdepan untuk memajukan bisnis Anda. Dengan pengalaman yang luas dan keahlian dalam transformasi digital, kami berkomitmen untuk membantu bisnis Anda tumbuh, berkembang, dan bersaing di era yang semakin terhubung secara digital.</p>

            <p className="my-5 text-justify leading-8">Visi kami adalah menciptakan perubahan positif dalam dunia bisnis melalui pemanfaatan teknologi terkini. Kami percaya bahwa transformasi digital bukanlah sekadar pilihan, melainkan kebutuhan yang mendesak untuk mempercepat kesuksesan dan keunggulan bisnis.</p>

            <p className="my-5 text-justify leading-8">Kami mengerti bahwa setiap bisnis memiliki kebutuhan yang unik. Oleh karena itu, tim ahli kami bekerja sama dengan Anda untuk memahami tujuan, tantangan, dan kebutuhan bisnis Anda. Dengan pendekatan yang terpersonalisasi, kami merancang dan mengimplementasikan solusi digital yang sesuai dengan kebutuhan spesifik Anda.</p>

            <p className="my-5 text-justify leading-8">Dengan produk dan layanan terbaik kami, kami membantu Anda meningkatkan efisiensi operasional, meningkatkan pengalaman pelanggan, memperluas pangsa pasar, dan meraih hasil yang maksimal. Dari pengembangan aplikasi berbasis web dan mobile, pengoptimalan e-commerce, hingga integrasi sistem, kami memiliki keahlian yang diperlukan untuk memenuhi kebutuhan teknologi bisnis Anda.</p>

            <p className="my-5 text-justify leading-8">Kami bangga memiliki tim yang terdiri dari para profesional berbakat dengan pengetahuan mendalam dalam bidang teknologi dan transformasi digital. Kami berkomitmen untuk menjaga diri kami tetap terdepan dalam tren dan inovasi terkini, sehingga dapat memberikan solusi terbaik kepada Anda.</p>

            <p className="my-5 text-justify leading-8">Kualitas adalah prioritas utama kami. Kami mengutamakan kepuasan pelanggan dengan memberikan layanan terbaik, dukungan teknis yang responsif, dan solusi yang andal dan aman. Kami menjalin hubungan jangka panjang dengan klien kami, membangun kepercayaan yang kokoh melalui prestasi dan keunggulan.</p>

            <p className="my-5 text-justify leading-8">Kami bersemangat untuk bekerja sama dengan Anda dalam menghadapi tantangan digital dan mewujudkan potensi bisnis Anda. Bersama, kita dapat meraih keberhasilan dan mengukir prestasi baru di dunia bisnis yang semakin terhubung secara digital. Hubungi kami hari ini dan mulailah perjalanan menuju transformasi digital yang sukses bersama kami.</p>
        </div>
        <Footer/>
    </>;
}

export default KebijakanPrivasi;
