
import './App.css';
import {Navbar} from "./Components/Navbar";
import {Hero} from "./Components/Hero";
import {Product} from "./Components/Product";
import {Explore} from "./Components/Explore";
import Contact from "./Components/Contact";
import {Footer} from "./Components/Footer";
import {PrivacyPolicy} from "./Components/PrivacyPolicy";

function KebijakanPrivasi() {
    return <>
        <Navbar></Navbar>
        <PrivacyPolicy/>
        <Footer/>
    </>;
}

export default KebijakanPrivasi;
