
import './App.css';
import {Navbar} from "./Components/Navbar";
import {Footer} from "./Components/Footer";
import {PrivacyPolicy} from "./Components/PrivacyPolicy";

function NotFound() {
    return <>
        <Navbar></Navbar>
        <div className="relative isolate px-6 pt-14 lg:px-8 min-h-screen">
            <h4 className="text-2xl">Halaman Tidak Ditemukan</h4>
            <p className="py-5">Mohon maaf link halaman yang anda cari tidak terdaftar di laman kami.</p>
            <a href="/" className="text-sm font-semibold leading-6 text-gray-900">
                Kembali Ke Halaman Utama <span aria-hidden="true">→</span>
            </a>
        </div>
        <Footer/>
    </>;
}

export default NotFound;
