import React, {useRef} from "react";
export default function Contact() {
    const namaRef = useRef()
    const emailRef = useRef()
    const hpRef = useRef()
    const pesanRef = useRef()
    const submit = (e) => {
        e.preventDefault()
        if(namaRef.current && pesanRef.current && emailRef.current && hpRef.current){
            const nama = namaRef.current.value
            const pesan = pesanRef.current.value
            const email = emailRef.current.value
            const hp = hpRef.current.value
            const template = `Nama : ${nama}\nEmail : ${email}\nHP : ${hp}\n${pesan}`
            window.open(`https://wa.me/6285927475702?text=${encodeURIComponent(template)}`, "_blank")
        }else{
            window.open(`https://wa.me/6285927475702`, "_blank")
        }
    }
    return (
        <form id="contact" className="px-12 py-10 md:px-56 md:py-20" onSubmit={(e) => submit(e)}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-2xl font-semibold leading-7 text-gray-900">Hubungi Kami</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Harap isikan informasi berikut ini.</p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                Nama
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="nama"
                                    id="nama"
                                    autoComplete="nama"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required={true}
                                    ref={namaRef}
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Alamat Email
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required={true}
                                    ref={emailRef}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Nomor HP
                            </label>
                            <div className="mt-2">
                                <input
                                    id="hp"
                                    name="hp"
                                    type="text"
                                    autoComplete="hp"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required={true}
                                    ref={hpRef}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Pesan
                            </label>
                            <div className="mt-2">
                                <textarea
                                    id="pesan"
                                    name="pesan"
                                    autoComplete="pesan"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    required={true}
                                    ref={pesanRef}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="mt-6 flex items-center justify-center gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"

                >
                    Kirim
                </button>
            </div>
        </form>
    )
}