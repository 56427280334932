import React from "react";
import ifood from "../Product/fge8hkty.bmp";
import iresto from "../Product/a2lxr82b.bmp";
import isayur from "../Product/rbzzxql3.bmp";
export function Product() {

    const data = [
        {
            name: "iFood",
            desc: "Nikmati makanan hangat yang dimasak oleh Chef professional, kami sajikan makanan yang pastinya melebihi ekspektasimu.",
            img: ifood
        },
        {
            name: "iSayur",
            desc: "Tidak perlu lagi keluar rumah untuk belanja buah, sayur ataupun keperluan dapur sehari-hari, cukup pesan dan kami akan antar ke tempatmu.",
            img: isayur
        },
        {
            name: "iResto",
            desc: "Kelola restoran Anda dengan mudah dengan aplikasi POS iResto. Customer tinggal pesan melalui QR Code, transaksi langsung tercatat di sistem.",
            img: iresto
        }
    ]

    return <div id="product" className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <h3 className="text-white text-center text-4xl md:text-6xl font-bold pb-16">Produk Kami</h3>
        {data.map((v,i) =>
            i % 2 === 0?<div className="flex flex-col sm:grid sm:grid-cols-2 md:px-36 px-12">
                <img className="w-full" src={v.img}/>
                <div className="mx-auto max-w-7xl px-6 lg:px-8 my-12 md:m-0">
                    <div className="mx-auto max-w-2xl lg:mx-0 flex flex-col items-center justify-center h-full">
                        <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">{v.name}</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            {v.desc}
                        </p>
                    </div>
                </div>
            </div>:<div className="flex flex-col-reverse sm:grid sm:grid-cols-2 md:px-36 px-12">
                <div className="mx-auto max-w-7xl px-6 lg:px-8 my-12 md:m-0">
                    <div className="mx-auto max-w-2xl lg:mx-0 flex flex-col items-center justify-center h-full">
                        <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">{v.name}</h2>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            {v.desc}
                        </p>
                    </div>
                </div>
                <img className="w-full" src={v.img}/>
            </div>)}
    </div>
}