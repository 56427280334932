import logo from "../trihakaLogo.png"
export function Footer() {
    return <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32 text-center">
        <img className="mx-auto my-2" src={logo} width={48}/>
        <p className="text-base text-gray-400 mb-10">&copy; 2023 PT.Tritama Hasil Karya Bersama</p>
        
        <div className="text-gray-400 flex justify-center gap-5">
            <a className="font-bold" href="/profil-kami">Profil Kami</a>
            <a className="font-bold" href="/kebijakan-privasi">Kebijakan Privasi</a>
        </div>
        
    </div>
}