import React, {useState} from "react";
import logo from '../trihaka.png';

export function Navbar(){
    const [open, setOpen] = useState()
    return <nav className="sticky top-0 z-30 px-2 w-full shadow-xl sm:px-4 bg-white">
        <div className="flex items-center justify-between mx-auto max-w-7xl">
            <a href="/">
                <img className="my-2" width={120} src={logo} alt={""}/>
            </a>
            <div className="flex items-center space-x-1">
                <ul className="hidden space-x-2 md:inline-flex">
                    <li><a href="/#product" className="px-4 py-2 font-semibold text-gray-600 rounded">Produk</a></li>
                    <li><a href="/profil-kami" className="px-4 py-2 font-semibold text-gray-600 rounded">Profil Kami</a></li>
                    <li><a href="/#contact" className="px-4 py-2 font-semibold text-gray-600 rounded">Hubungi Kami</a></li>
                </ul>
                <div className="inline-flex md:hidden">
                    <button className="flex-none px-2 " onClick={() => setOpen(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
                    </svg>
                    <span className="sr-only">Open Menu</span>
                </button>
                <div
                    className={"absolute top-0 left-0 right-0 z-50 flex flex-col p-2 pb-4 m-2 space-y-3 bg-white rounded shadow "+(!open && "hidden")}
                    >
                <button className="self-end flex-none px-2 ml-2" onClick={() => setOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span className="sr-only">Close Menu</span>
            </button>
            <ul className={"space-y-2 "+(!open && "hidden")}>
                <li><a href="/#product" className="px-4 py-2 font-semibold text-gray-600 rounded">Produk</a></li>
                <li><a href="/profil-kami" className="px-4 py-2 font-semibold text-gray-600 rounded">Profil Kami</a></li>
                <li><a href="/#contact" className="px-4 py-2 font-semibold text-gray-600 rounded">Hubungi Kami</a></li>
            </ul>
        </div>
            </div>
            </div>
        </div>


    </nav>
}